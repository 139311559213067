var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "wuwow-card"
  }, [_vm._m(0), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('b-row', {
    staticClass: "contact-us-box"
  }, [_c('b-col', {
    staticClass: "mt-5",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.pmQuestionImg
    }
  }), _c('h6', [_vm._v("我有課程上的問題")]), _c('div', {
    staticClass: "txt-contact-info"
  }, [_vm._v("教務部信箱"), _c('br'), _c('a', {
    attrs: {
      "href": `mailto:${_vm.companyConstant.INFO.PM_EMAIL}`
    }
  }, [_vm._v(_vm._s(_vm.companyConstant.INFO.PM_EMAIL))]), _c('br'), _c('span', [_vm._v("教務部專線: " + _vm._s(_vm.companyConstant.INFO.PM_NUMBER))])])]), _c('b-col', {
    staticClass: "mt-5",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.itQuestionImg
    }
  }), _c('h6', [_vm._v("我有電腦方面的問題")]), _c('div', {
    staticClass: "txt-contact-info"
  }, [_vm._v("技術客服信箱"), _c('br'), _c('a', {
    attrs: {
      "href": `mailto:${_vm.companyConstant.INFO.TS_EMAIL}`
    }
  }, [_vm._v(_vm._s(_vm.companyConstant.INFO.TS_EMAIL))]), _c('br'), _c('span', [_vm._v("技術客服專線: " + _vm._s(_vm.companyConstant.INFO.TS_NUMBER))])])]), _c('b-col', {
    staticClass: "mt-5",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.csQuestionImg
    }
  }), _c('h6', [_vm._v("我想要課程諮詢")]), _c('div', {
    staticClass: "txt-contact-info"
  }, [_vm._v("課程諮詢信箱"), _c('br'), _c('a', {
    attrs: {
      "href": `mailto:${_vm.companyConstant.INFO.SERVICE_EMAIL}`
    }
  }, [_vm._v(_vm._s(_vm.companyConstant.INFO.SERVICE_EMAIL))]), _c('br'), _c('span', [_vm._v("課程諮詢專線: " + _vm._s(_vm.companyConstant.INFO.SERVICE_NUMBER))]), _c('br'), _c('span', [_vm._v("Line 客服:"), _c('a', {
    staticClass: "ml-1",
    attrs: {
      "href": _vm.companyConstant.INFO.LINE_URL
    }
  }, [_vm._v(_vm._s(_vm.companyConstant.INFO.LINE_ID))])])])])], 1)], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('div', {
    staticClass: "wuwow-card-title"
  }, [_c('h4', [_vm._v("聯絡我們")])])]);

}]

export { render, staticRenderFns }