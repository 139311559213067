<template lang="pug">
div
  .wuwow-card
    .wuwow-card-head
      .wuwow-card-title
        h4 聯絡我們
    .wuwow-card-body
      b-row.contact-us-box
        b-col.mt-5(cols="12" md="4")
          img(:src="pmQuestionImg")
          h6 我有課程上的問題
          .txt-contact-info 教務部信箱
            br
            a(:href="`mailto:${companyConstant.INFO.PM_EMAIL}`") {{ companyConstant.INFO.PM_EMAIL }}
            br
            span 教務部專線: {{ companyConstant.INFO.PM_NUMBER }}
        b-col.mt-5(cols="12" md="4")
          img(:src="itQuestionImg")
          h6 我有電腦方面的問題
          .txt-contact-info 技術客服信箱
            br
            a(:href="`mailto:${companyConstant.INFO.TS_EMAIL}`") {{ companyConstant.INFO.TS_EMAIL }}
            br
            span 技術客服專線: {{ companyConstant.INFO.TS_NUMBER }}
        b-col.mt-5(cols="12" md="4")
          img(:src="csQuestionImg")
          h6 我想要課程諮詢
          .txt-contact-info 課程諮詢信箱
            br
            a(:href="`mailto:${companyConstant.INFO.SERVICE_EMAIL}`") {{ companyConstant.INFO.SERVICE_EMAIL }}
            br
            span 課程諮詢專線: {{ companyConstant.INFO.SERVICE_NUMBER }}
            br
            span Line 客服:
              a.ml-1(:href="companyConstant.INFO.LINE_URL") {{ companyConstant.INFO.LINE_ID }}
</template>

<script>
import companyConstant from '@/constants/company';
import csQuestionImg from '@/assets/cs-question.png';
import itQuestionImg from '@/assets/it-question.png';
import pmQuestionImg from '@/assets/pm-question.png';
export default {
  name: 'ContactUs',

  data() {
    return {
      companyConstant,
      csQuestionImg,
      itQuestionImg,
      pmQuestionImg,
    };
  },
};
</script>
<style scoped>
.contact-us-box{
  text-align: center;
}
h6{
  font-weight: 600;
}
</style>
